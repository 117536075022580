<template>
  <div class="row">
    <div class="col-6 d-flex flex-column">
      <p>系統編號：{{ customer.id }}</p>
      <p>來源通路：{{ customer.merchant.type }}:{{ customer.merchant.name }}:{{ customer.merchant.type_name }}</p>
      <p>系統編號：{{ customer.outer_code }}</p>
      <p>姓名：{{ customer.name }}</p>
    </div>
    <div class="col-12">
      <b-button :variant="isActive('CustomerDetailView')" @click="go('CustomerDetailView')">基本資料</b-button>
      <b-button
        v-if="customer.has_cross_merchant"
        :variant="isActive('CustomerListByUserID')"
        @click="go('CustomerListByUserID')"
      >
        來源通路
      </b-button>
      <b-button
        v-if="$permissions.has($permissions.consts.CUSTOMER_STAFF_VIEW)"
        :variant="isActive('CustomerStaffList')"
        @click="go('CustomerStaffList')"
      >
        所屬業務
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customer: {
      merchant: {}
    },
  },
  methods: {
    go(pageName) {
      this.$router.push({
        name: pageName, params: { customerID: this.customer.id },
      });
    },
    isActive(pageName) {
      return this.$route.name === pageName ? 'inverse-danger' : 'inverse-dark';
    }
  }
}
</script>

<style scoped>
.btn + .btn {
  margin-left: 1rem;
}
</style>
